.main {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  background-color: $primary_3;
  width: 100%;
  margin: 0;
  padding: 0;
}

.auth-form {
  background-color: $primary_5;
  border: 1px solid $primary_2;
  display: flex;
  flex-direction: column;
  padding: 33px 34.5px;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: 66px 69px;
  }

  h1, h2, h3 {
    color: $secondary_1;
    font-family: 'Avenir Heavy';
    font-size: 24px;
    font-weight: 900;
  }

  &__logo {
    margin: 0 0 40px 0;
    max-width: 252px;
    padding: 0;

    img {
      height: auto;
      width: 100%;
    }
  }

  &__container {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  &__subtitle {
    font-family: 'Avenir Heavy';
    color: $primary_1;
    font-size: 14px;
    font-weight: 900;
  }

  &__field {
    position: relative;

    button.field-action {
      align-items: center;
      background-color: transparent;
      border: none;
      display: flex;
      height: 25px;
      justify-content: center;
      position: absolute;
      right: 25px;
      top: 40px;
      width: 25px;
    }
  }

  &__twoauth {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    label {
      text-align: center;
      width: 300px;
      font-size: 14px;
      font-weight: 300;
    }

    button.field-action {
      align-items: center;
      background-color: transparent;
      border: none;
      display: flex;
      height: 25px;
      justify-content: center;
      position: absolute;
      right: 25px;
      top: 40px;
      width: 25px;
    }
  }

  &__link {
    font-family: 'Avenir';
    color: $secondary_3;
    font-weight: 400;
    font-size: 12px;
    text-decoration: none;
    &:hover {
      color: $secondary_3;
      text-decoration:underline,
    }
  }
  &__message {
    font-family: 'Avenir';
    margin-top: 8px;
    font-size: 12px;
    color: $red_1;
  }
  &__submit {
    background-color: $secondary_1;
    font-family: 'Avenir Medium';
    width: 100%;

    &[disabled] {
      background-color: $secondary_5;
      border: none;
      color: $secondary_3;
      font-weight: medium;
      height: 53px;
    }

    &:hover {
      background-color: $secondary_2;
    }

    &:active {
      background-color: $secondary_1;
    }
  }
}

.btn-global {
  @extend .order-btn;
  background-color: transparent;
  &--approve {
    @extend .btn-global;
    color: $secondary_1;
    border: 1px solid $green_1;
    &--icon {
      filter: brightness(0) saturate(100%) 
      invert(13%) sepia(81%) saturate(5814%) 
      hue-rotate(166deg) brightness(98%) contrast(84%);
    }

    &:hover {
      background-color: $green_1;
      color: $white;
      .btn-global--approve--icon {
        filter: brightness(0) saturate(100%)
        invert(100%) sepia(1%) saturate(2%) 
        hue-rotate(198deg) brightness(116%) contrast(100%);
      }
    }
  }

  &--reject {
    @extend .btn-global;
    color: $secondary_1;
    border: 1px solid $red_1;
    &--icon {
      filter: brightness(0) saturate(100%) 
      invert(83%) sepia(37%) saturate(6274%)
      hue-rotate(314deg) brightness(100%) contrast(100%);
    }
    &:hover {
      background-color: $red_1;
      color: $white;
      .btn-global--reject--icon {
        filter: brightness(0) saturate(100%)
        invert(100%) sepia(1%) saturate(2%) 
        hue-rotate(198deg) brightness(116%) contrast(100%);
      }
    }
  }

  &--submit {
    background-color: $secondary_1;
    font-family: 'Avenir Medium';
    font-size:14px;
    min-width:115px;
    border-radius:0px;
    &:hover {
      background-color: $secondary_2;
    }
  }
}
.main-profile {
  background-color: $white;
}
