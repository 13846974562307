.order-preview {
  background: $primary_5;
  box-shadow: 0px 3px 6px $shadow_1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16.5px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 20;

  @include media-breakpoint-up(lg) {
    padding: 50px;
    width: 770px;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
    transition: 350ms;
  }

  button.close:hover {
    transform: rotate(45deg);
    transition: 350ms;
  }

  button.close {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: 350ms;
  }

  &__action {
    background: none;
    border: none;
    font-size: 20px;
    font-weight: bold;
    height: 20px;

    span:before {
      color: $primary_1!important;
      font-size: 20px;
      height: auto;
    }
  }

  &__cards {
    display: flex;
    flex-flow: column nowrap;
    gap: 16.5px;
    justify-content: flex-start;
    align-items: center;

    @include media-breakpoint-up(lg) {
      align-items: center;
      justify-content: center;
      flex-flow: row nowrap;
    }

    .order-detail-card {
      height: 100%;
      width: 100%;
    }
  }
}
