.calendar-preview {
  background: $primary_5;
  box-shadow: 0px 3px 6px $shadow_1;
  flex-direction: column;
  margin-top: 20px;
  padding: 10px;
  width: 350px;
  height: 400px;
  &.display_calendar_show{
    display: flex;
  }
  &.display_calendar_hide{
    display: none;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 40px;
    justify-content: space-between;
    margin-bottom: 3px;
    width: 100%;
  }
  &__action {
    background: none;
    border: none;
    font-size: 20px;
    font-weight: bold;
    height: 20px;

    span:before {
      color: $primary_1!important;
      font-size: 20px;
      height: auto;
    }
  }

}
