.main-doctor-information{
    display:flex;
    background-color:$white;
    flex-wrap:wrap;
    justify-content: space-between;

    &--profile {
        display:flex;
        border-right: 1px solid $primary_3;
        @include media-breakpoint-down(md) {
            border-right: none;
          }
    }
    &--header {
        @extend .main-doctor-information;
        align-items: center;
        border-bottom: 1px solid $primary_3;
    }

}
.form-check{
    &-input:checked{
        background-color: #9f6527;
        border-color: #9F6527;
    }
    &-label {
        font-size:12px;
        color:$secondary_1;
        font-family:'Avenir';
        min-width: 120px;
        max-width: 130px;
        word-break: break-all;
    }
}

.form-check-detail{
    & input[type="checkbox"]{
        @extend .form-check-input:checked;
        border-radius:16px;
        margin-right:8px;
    }
}
.container-detail-doctor-product{
    height: 100%;
    width: 100%;
    max-height: 95px;
    overflow-y: auto;
    padding-right: 20px;
}

.container-detail-doctor-licenses{
    @extend .container-detail-doctor-product;
    & div {
        min-width: 80px;
        max-width: 80px;
        justify-content: flex-start !important;
    }
}

.list-group-item {
    border: none;
}

.header-doctor-detail{
    margin: '0px 20px';
}