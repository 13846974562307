.main-dashboard {
  position: relative;
  background-color: $primary_5;
}

.primary-btn {
  @extend .auth-form__submit;
  background-color: $primary_1;
  border-color: $primary_1;
  width: auto;
  height: auto!important;
}

button.close {
  margin: 0;
  border: none!important;
  border-radius: 50%;
  background-color: transparent;
  height: 30px;
  width: 30px;
  font-size: 1.3rem;
  padding: 0;

  &:hover {
    background-color: $primary_3;
  }
}

textarea {
  width: 300px;
  height: 150px;
}