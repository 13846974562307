$form-gap: 15px;

.doctor-form {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  justify-items: center;
  grid-gap: 15px;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
  }

  &__field {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
//Modal date
.rdrMonth {
  width: 100%;
}
