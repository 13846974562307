// Resets
.form-group {
  margin-top: 20px;
  text-align: left;
  width: 100%;

  &__formlabel {
    color: $secondary_1;
    font-family: 'Avenir Medium';
    margin-bottom: 4px;
    font-size: 12px;
  }

  label {
    color: $secondary_1;
    font-family: 'Avenir Medium';
    font-weight: medium;
    margin-bottom: 4px;
  }

  input.form-control,
  select.form-control {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 48px;
    font-size: 14px;
    padding: 0 8px;
  }

  input:focus,
  select:focus {
    background-color: $white;
    border: 1px solid $primary_1;
    box-shadow: none;
    outline-style: none;
    outline: none;
  }
  [type='file'] {
    position: absolute;
    width: 100%;
    top: 0;
    opacity: 0;
    left: 0;
    height: 100%;
  }

  &__title {
    color: $secondary_1;
    font-family: 'Avenir Medium';
    font-size: 16px;
  }

  &__uploadlabel {
    margin-bottom: 0;
    padding: 4px 0px;
    span {
      font-family: 'Avenir';
      color: $secondary_3;
      font-size: 14px;
    }
  }
}

label.full-width {
  max-width: 100%;
  word-break: normal;
  font-size: 14px;
  font-weight: 600;
}

.form-check-input {
  &:focus,
  &:hover {
    box-shadow: none;
    border-color: #9f6527;
  }
}

.selectOrderDoctor {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 48px;
  font-size: 14px;
  padding: 0 8px;
  padding-left: 30px !important;
  cursor: pointer;
}

.form-select {
  background-image: url('~assets/ic_icons/ic_arrow.svg');
  background-size: 18px 19px;
}

// Pagination
.main-ui__pagination__item button {
  background-color: transparent;
  border-color: transparent;
  border-radius: 50% !important;
  color: $primary_1 !important;
  font-size: bold;
  height: 31px;
  width: 31px;
}

li.main-ui__pagination__item a {
  align-items: center;
  background-color: transparent !important;
  border-color: transparent;
  color: $secondary_1;
  display: flex;
  height: 31px;
  justify-content: center;
  padding: 0;
  width: 31px;
}

li.main-ui__pagination__item--active a {
  align-items: center;
  background-color: $secondary_1 !important;
  border-color: $secondary_1 !important;
  border-radius: 50% !important;
  display: flex;
  height: 31px;
  justify-content: center;
  padding: 0;
  width: 31px;
}

.primary-btn {
  @extend .auth-form__submit;
  background-color: $primary_1;
  border-color: $primary_1;
  width: auto;
  height: auto !important;
}

button.close {
  margin: 0;
  border: none !important;
  border-radius: 50%;
  background-color: transparent;
  height: 30px;
  width: 30px;
  font-size: 1.3rem;
  padding: 0;

  &:hover {
    background-color: $primary_3;
  }
}

textarea {
  height: 150px;
}

// Toastify
.Toastify__toast--success {
  border-left: 12px solid $green_1;
  background-color: $primary_5;
  min-width: 335px;
}

.Toastify__toast--error {
  border-left: 12px solid $red_1;
  background-color: $primary_5;
  min-width: 335px;
}

.Toastify__toast-icon {
  display: none;
}

.input-signature {
  background-color: $primary_4;
  border-radius: 10px;
  border: none;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  padding: 20px;
  width: 100%;

  &__trigger {
    background: transparent;
    border: 4px dotted #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__preview {
    align-items: center;
    border: 4px dotted #ccc;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  figure {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: auto;
    justify-items: center;
    margin: 10px 0;
    width: 100%;
    position: relative;
  }

  &__close {
    align-items: center;
    background-color: $secondary_1;
    border-radius: 50%;
    border: none;
    box-shadow: 0 3 6px #000;
    color: $white;
    display: flex;
    font-size: 12px;
    height: 20px;
    justify-content: center;
    position: absolute;
    right: -5px;
    top: -5px;
    width: 20px;
  }

  figure.preview {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: auto;
    justify-items: center;
    margin: 10px 0;
    width: 100px;
    position: relative;
  }

  figure img {
    border-radius: 10px;
    height: 80px;
    width: 80px;
  }

  &__footer {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    justify-content: flex-start;
    margin-top: 10px;
  }
}
