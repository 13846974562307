$ui-header-height-md: 100px;
$ui-header-height-sm: 70px;

.ui-header {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $primary_3;
  display: flex;
  flex-flow: row nowrap;
  height: $ui-header-height-sm;
  padding: 0;
  position: sticky;
  top: 0px;
  z-index: 10;

  @include media-breakpoint-up(md) {
    height: $ui-header-height-md;
  }

  &__logo {
    margin: 0;
    max-width: 30px;
    padding: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      max-width: 252px;
    }

    img {
      height: auto;
      width: 100%;
    }

    &--md {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    &--sm {
      display: block;
      max-height: calc(#{$ui-header-height-md} - 40px);

      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  &__nav ul {
    font-family: "Avenir Medium";
    font-size: 16px;
    height: $ui-header-height-sm;
    list-style: none;

    @include media-breakpoint-up(md) {
      height: $ui-header-height-md;
    }
  }

  &__nav .btn {
    font-size: 16px;
    font-weight: 500;
    color: $secondary_1;
  }

  &__navicon {
    font-size: 16px;
    margin: 0 0 0 9px;
    padding: 0;

    span::before {
      color: $primary_1;
    }
  }

  &__tab {
    min-width: 85px;
    height: 100%;
    margin-left: 10px;

    @include media-breakpoint-up(md) {
      max-width: 252px;
    }
  }

  &__tab--active {
    @extend .ui-header__tab;

    & > a {
      border-bottom: 2px solid $primary_1!important;
    }
  }

  &__tab > a {
    align-items: center;
    border-bottom: 2px solid transparent!important;
    color: $secondary_1;
    display: flex;
    flex-flow: row nowrap;
    font-family: "Avenir Medium";
    font-size: 16px;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  &__tab > a > figure {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    margin: 0px 8px 0px 0px;
    padding: 0;

    span:before {
      height: auto;
      font-size: 20px;
      color: $primary_1!important;
    }
  }

  &__tab {
    min-width: 85px;
    height: 100%;
    margin-left: 10px;

    @include media-breakpoint-up(md) {
      max-width: 252px;
    }
  }

  &__tab--active {
    @extend .ui-header__tab;

    & > a {
      border-bottom: 2px solid $primary_1!important;
    }
  }

  &__tab > a {
    align-items: center;
    border-bottom: 2px solid $white;
    display: flex;
    flex-flow: row nowrap;
    font-family: "Avenir Medium";
    font-size: 16px;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  &__tab > a > figure {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    margin: 0px 8px 0px 0px;
    padding: 0;

    img {
      height: auto;
      width: 20px;
    }

    img > path {
      fill: $primary_1;
    }
  }
}
