.dropdown{
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 48px;
  font-size: 14px;
  border-radius:4px;
  position: relative;
  z-index:1;

  &-btn{
    @extend .dropdown;
    background-color: $white;
    border: 1px solid $primary_3;
    color: #6b7885;
    font-size: 14px;
    padding: 0px 8px;
    width: 100%;
    font-family:'Avenir';
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    position: absolute;
    z-index:3;
    img {
      fill: $primary_1;
      transform: rotate(90deg);
    }
  }

  &-content{
    position: absolute;
    top: 110%;
    left: 0;
    padding: 10px;
    background-color: $white;
    border: 1px solid $primary_3;
    color: #6b7885;
    width: 100%;
    z-index:5;
  }

  &-item{
    cursor: pointer;
    transition: all 0.2s;
  }
}