/**
 * Colors
 * ======================================================================================================= **/

$primary_1: #9f6527;
$primary_2: #c4b3a6;
$primary_3: #e5dad2;
$primary_4: #f5f1ef;
$primary_5: #f6f5f6;
$white: #fff;

$secondary_1: #0f1422;
$secondary_2: #313a4f;
$secondary_3: #5e6881;
$secondary_4: #adb9d6;
$secondary_5: #e6eaf4;

$green_1: #14795b;
$red_1: #fe786f;
$yellow_1: #f8a939;

$shadow_1: #00000029;

/**
 * Fonts
 * ======================================================================================================= **/

// Avenir Light
@font-face {
  font-family: 'Avenir';
  src: url('~assets/fonts/AvenirProLight/AvenirLTPro-Light.eot');
  src: url('~assets/fonts/AvenirProLight/AvenirLTPro-Light.eot?#iefix')
      format('embedded-opentype'),
    url('~assets/fonts/AvenirProLight/AvenirLTPro-Light.woff2') format('woff2'),
    url('~assets/fonts/AvenirProLight/AvenirLTPro-Light.woff') format('woff'),
    url('~assets/fonts/AvenirProLight/AvenirLTPro-Light.ttf') format('truetype'),
    url('~assets/fonts/AvenirProLight/AvenirLTPro-Light.svg#AvenirLTPro-Light')
      format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// Avenir Medium
@font-face {
  font-family: 'Avenir Medium';
  src: url('~assets/fonts/AvenirProMedium/AvenirLTPro-Medium.eot');
  src: url('~assets/fonts/AvenirProMedium/AvenirLTPro-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('~assets/fonts/AvenirProMedium/AvenirLTPro-Medium.woff2')
      format('woff2'),
    url('~assets/fonts/AvenirProMedium/AvenirLTPro-Medium.woff') format('woff'),
    url('~assets/fonts/AvenirProMedium/AvenirLTPro-Medium.ttf')
      format('truetype'),
    url('~assets/fonts/AvenirProMedium/AvenirLTPro-Medium.svg#AvenirLTPro-Medium')
      format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// Avenir Heavey
@font-face {
  font-family: 'Avenir Heavy';
  src: url('~assets/fonts/AvenirProHeavy/AvenirLTPro-Heavy.eot');
  src: url('~assets/fonts/AvenirProHeavy/AvenirLTPro-Heavy.eot?#iefix')
      format('embedded-opentype'),
    url('~assets/fonts/AvenirProHeavy/AvenirLTPro-Heavy.woff2') format('woff2'),
    url('~assets/fonts/AvenirProHeavy/AvenirLTPro-Heavy.woff') format('woff'),
    url('~assets/fonts/AvenirProHeavy/AvenirLTPro-Heavy.ttf') format('truetype'),
    url('~assets/fonts/AvenirProHeavy/AvenirLTPro-Heavy.svg#AvenirLTPro-Heavy')
      format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/**
 * IconFonts
 * ======================================================================================================= **/

@font-face {
  font-family: 'icomoon';
  src: url('~assets/fonts/7CellsIcons/icomoon.eot?wr7h90');
  src: url('~assets/fonts/7CellsIcons/icomoon.eot?wr7h90#iefix')
      format('embedded-opentype'),
    url('~assets/fonts/7CellsIcons/icomoon.ttf?wr7h90') format('truetype'),
    url('~assets/fonts/7CellsIcons/icomoon.woff?wr7h90') format('woff'),
    url('~assets/fonts/7CellsIcons/icomoon.svg?wr7h90#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic_calendar_check:before {
  content: '\e90e';
}
.icon-ic_calendar_plus:before {
  content: '\e920';
}
.icon-ic_calendar_times:before {
  content: '\e921';
}
.icon-ic_calendar_alt:before {
  content: '\e922';
}
.icon-ic_add_outline:before {
  content: '\e900';
}
.icon-ic_arrow_back:before {
  content: '\e901';
}
.icon-ic_check_outline:before {
  content: '\e902';
}
.icon-ic_check_circle:before {
  content: '\e903';
}
.icon-ic_arrow_down:before {
  content: '\e904';
}
.icon-ic_arrow_left:before {
  content: '\e905';
}
.icon-ic_arrow_right:before {
  content: '\e906';
}
.icon-ic_arrow_up:before {
  content: '\e907';
}
.icon-ic_edit:before {
  content: '\e908';
}
.icon-ic_eye_close:before {
  content: '\e909';
}
.icon-ic_eye_open:before {
  content: '\e90a';
}
.icon-ic_form:before {
  content: '\e90b';
}
.icon-ic_location:before {
  content: '\e90c';
}
.icon-ic_lock:before {
  content: '\e90d';
}
.icon-ic_logout:before {
  content: '\e90f';
}
.icon-ic_mail:before {
  content: '\e910';
}
.icon-ic_phone:before {
  content: '\e911';
}
.icon-ic_phone_cancel:before {
  content: '\e912';
}
.icon-ic_review:before {
  content: '\e913';
}
.icon-ic_search:before {
  content: '\e914';
}
.icon-ic_trash:before {
  content: '\e915';
}
.icon-ic_user:before {
  content: '\e916';
}
.icon-ic_feather_upload:before {
  content: '\e917';
}
.icon-ic_info:before {
  content: '\e918';
}
.icon-ic_minitag_out:before {
  content: '\e919';
}
.icon-ic_tag_check:before {
  content: '\e91a';
}
.icon-ic_tag_outline:before {
  content: '\e91b';
}
.icon-ic_tag_close_outline:before {
  content: '\e91c';
}
.icon-ic_tag_close:before {
  content: '\e91d';
}
.icon-ic_close_outline:before {
  content: '\e91e';
}
.icon-ic_close_delete:before {
  content: '\e91e';
  color: #0f1422;
}
.icon-ic_tag_close_circle:before {
  content: '\e91f';
}
