
.main-restore-password{
  @extend .main;
  flex-direction: column;
  &__container {
    margin-top: 30px;
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: left;
    line-height: 28px;
    width: 274px;
  }
  h4 {
    font-family: 'Avenir';
    color: $secondary_3;
    font-size: 16px;
    font-weight: 900;
  }
  &__link {
    font-family: 'Avenir';
    color: $primary_1;
    font-size: 16px;
    font-weight: 900;
    &:hover {
      color: $primary_1;
    }
  }
  strong{
    font-family: 'Avenir Heavy';
    color: $secondary_1;
  }
}

.redirect-btn {
  font-family: 'Avenir Medium';
  font-size: 16px;
  color: $secondary_1;
  text-decoration: none;
  &:hover {
    color: $secondary_1;
    text-decoration: none;
  }
}
